.broker-create-account {
  .background_img {
    background-image: url("../../assets/investor/inv_signup.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 0 15%;
    z-index: 0;
    height: 80vh;
    @media (max-width: 1024px) {
      @media (max-height: 600px) {
        height: 85vh;
      }
      @media (min-height: 768px) {
        height: 75vh;
      }
      @media (min-height: 900px) {
        height: 45vh;
      }
    }
    @media (min-width: 1280px) {
      @media (max-height: 650px) {
        height: 85vh;
      }
      @media (min-height: 800px) {
        height: 70vh;
      }
      @media (min-height: 950px) {
        height: 60vh;
      }
      @media (min-height: 1150px) {
        height: 45vh;
      }
    }
  }
  .agent_logo {
    max-height: 75px;
  }
  .login_button {
    width: 35%;
  }
  .login-container {
    border-radius: 10px;
  }
  .servicer-signup-form {
    p {
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      text-align: left !important;
      margin-top: 3px;
    }
  }
  .resetPwd {
    span {
      font-family: IBM Plex Sans;
      font-weight: 500;
      font-size: 12px;
      line-height: 25px;
      text-align: center;
      vertical-align: middle;
      text-decoration: underline;
      text-decoration-style: solid;
      color: #0395ff;
      text-transform: none;
    }
  }
  .agent-properties {
    @media (max-width: 1024px) {
      @media (max-height: 600px) {
        height: 90vh;
      }
    }
  }
  .broker_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px 20px 150px;
    @media (max-width: 1200px) {
      padding: 30px 70px 20px;
    }
  }
  .investor-list {
    min-height: auto !important;
    height: auto;
    margin-bottom: 20px;
  }
  .call_us_btn {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    border-radius: 20px;
    padding: 8px 20px;
    margin: 0 3px;
    background: #0155b7;
  }
  .proeprty_status {
    color: #ffffff;
    border-radius: 21px;
    padding: 6px 20px;
    gap: 10px;
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    text-transform: uppercase;
  }
  .sent {
    background: #f7b23b;
  }
  .accepted {
    background: #15be53;
  }
  .agents_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    button {
      border-radius: 29px;
      padding: 15px 40px;
      gap: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .save-agents-btn {
      display: flex;
      align-items: center;
      background: #0cc04e;
      letter-spacing: 5%;
      border: none;
      text-transform: uppercase;
      color: #0d120b;
    }
    .add-property-btn {
      border-width: 1px;
      border: 1px solid #04152126;
      background: #ffffff;
      color: #0155b7;
    }
  }

  .main-text {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -3%;
    text-align: center;
  }
  .paragraph_texts {
    @media (min-width: 1200px) {
      max-width: fit-content;
    }
  }
  .create_acc_section {
    padding-top: 80px;
    .agent_button {
      display: flex;
      justify-content: end;
      align-items: center;
      position: relative;
      bottom: 0;
      top: 12px;
      button {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 40px;
        width: auto;
        border-radius: 20px;
        padding: 5px 20px;
        gap: 5px;
        background: #f9f9f9;
        border: none;
        font-family: IBM Plex Sans;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: #0d120b;
      }
    }
  }
  .agent_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding-bottom: 20px;
    padding-left: 6%;
    button {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 40px;
      width: auto;
      border-radius: 20px;
      padding: 5px 20px;
      gap: 5px;
      background: #f9f9f9;
      border: none;
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      color: #0d120b;
    }
    .premier_btn {
      background: linear-gradient(90deg, #0cc04e 0%, #00a6b5 100%);
      height: 50px;
      border-radius: 29px;
      padding: 15px 30px;
      gap: 10px;
      color: #ffffff;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 5%;
      text-transform: uppercase;
    }
  }
  .paragraph-text {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0%;
    text-align: center;
  }
  .create_acc_form_wrapper {
    position: relative;
    margin-top: -250px;
    .login-container {
      max-width: 65%;
      border-radius: 10px;
    }
    .main-container {
      max-width: 80%;
      border-radius: 10px;
    }

    .properties-main-container {
      max-width: 90%;
      border-radius: 10px;
      padding: 50px;
    }
    .box-container {
      padding: 50px 0px 50px 20px;
    }
    .agents_form {
      .MuiInputBase-root {
        height: 46px;
        input {
          height: 13px;
        }
      }
      .phone-number-input,
      .googleAutoComplete {
        height: 46px;
        padding-right: 0px !important;
      }
      .error_border {
        border: 1px solid red !important;
      }

      .dropzone {
        width: 145px;
        height: 145px;
        border-width: 1px;
        border-radius: 8px;
        border: 1px dashed #0155b7;
        background: #f6f9fc;
      }
      .MuiButton-outlinedPrimary {
        display: flex;
        align-items: center;
        font-family: IBM Plex Sans;
        font-weight: 600;
        font-size: 14px !important;
        line-height: 20px;
        color: #0155b7 !important;
        border: 1px solid #0155b7;
        border-radius: 5px !important;
        border-width: 1px;
        padding: 5px 10px !important;
      }
      .files_texts {
        font-family: IBM Plex Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-top: 20px;
        padding-right: 50px;
      }
      .terms_texts {
        font-family: IBM Plex Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4c524a !important;
        u {
          color: #4c524a !important;
          font-family: IBM Plex Sans;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-decoration: underline;
          text-decoration-style: solid;
        }
      }
      .next_button {
        color: #0d120b !important;
      }
    }
    .MuiInputLabel-root {
      font-family: IBM Plex Sans;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: #0d120b !important;
      text-align: left;
    }
    .reset_password {
      .accountBoxWrapper {
        padding: 50px 50px 50px 75px !important;
      }
    }
    .create_password {
      .accountBoxWrapper {
        padding: 20px 0 !important;
      }
    }
  }
  .help-text {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #2d2d2d;
    margin: 20px 0;
    cursor: pointer;
    text-decoration: underline;
  }
  .standard-phone-field {
    background-color: #fff;
    border: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    .PhoneInputInput {
      background-color: #fff;
    }
  }
  .standard-select-field {
    .MuiSelect-select {
      background-color: transparent;
    }
  }
  .investor-list {
    background-color: transparent !important;
    th {
      background-color: #f3f4f5 !important;
    }
  }
  .Form_Wrapper {
    label {
      text-align: start;
    }
  }
  .owner_form {
    padding-right: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .content-heading {
      font-family: IBM Plex Sans;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: end;
      padding-right: 10px;
    }
    .property-textfields {
      width: 45%;
      padding: 0 5px;
      height: 90px;
      .sign-textField {
        margin-top: 15px;
      }
      .phone-number-input {
        margin-top: 15px;
        padding-right: 0 !important;
        border: 1px solid;
        background-color: white;
        .PhoneInputInput {
          padding-left: 5px;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid #344b5b1a;
        color: #0d120b;
        font-family: IBM Plex Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .popup_btn {
      padding: 8px 25px;
      margin-left: 15px;
      margin-top: -5px;
    }
  }
  .frame_class {
    width: 440px;
    height: auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #112a59;
  }
  .premier_content {
    text-align: left;
    padding: 4cqh 35px 10px 25px;
    p {
      font-family: IBM Plex Sans;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .premier_title {
      color: #0155b7;
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      padding-bottom: 10px;
    }
    .premier_subtitle {
      color: #0155b7;
      font-weight: 600;
      line-height: 100%;
      padding: 20px 0 8px;
    }
    .text-title {
      font-weight: 600;
      line-height: 150%;
      color: #0d120b;
      padding-left: 15px;
    }
    .text-description {
      font-weight: 400;
      line-height: 150%;
      color: #4c524a;
      padding-top: 5px;
    }
    .check_img {
      width: 20px;
      height: 20px !important;
      margin-top: 3px;
    }
    .agent_button {
      padding-left: 8px;
      padding-top: 30px;
    }
  }
  .ep_logo {
    width: 140px;
    // height: 38px;
    margin: 25px 20px;
  }
  .pay_ep_section {
    padding: 0px 40px 30px 20px;
  }
  .payment_frame {
    width: 444px;
    border-radius: 8px;
    border-width: 1px;
    background: #fbfbfb;
    border: 1px solid #e6e6e6;
    .payment_form {
      padding: 30px 5% 20px 7%;
      .sign-textField {
        // width: 384px;
        svg,
        .MuiSelect-select {
          z-index: 1;
        }
        fieldset {
          background: white;
        }
      }
      .field_border {
        fieldset {
          border: 1px solid #e5e5e5 !important;
        }
      }
      .card_elements {
        // border-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: "flex";
        align-items: "center";
        background-color: white;
        border: 1px solid #e5e5e5;
        // border-bottom: none;
        .StripeElement {
          box-shadow: none;
          border: none;
          border-radius: 0;
          border-top-left-radius: 10px;
        }
      }
      .card_exp_cvc {
        .exp_input {
          .StripeElement {
            border: 1px solid #cccccc8c;
            border-top: none;
            border-radius: 0;
            border-right: none;
            border-bottom-left-radius: 10px;
          }
        }
        .cvc_input {
          .StripeElement {
            border: 1px solid #cccccc8c;
            border-top: none;
            border-radius: 0;
            border-left: none;
            border-bottom-right-radius: 10px;
          }
        }
      }
      .MuiInputBase-root {
        height: 42px;
        border-radius: 8px;
        input {
          height: 8px;
          border-radius: 10px;
          border-width: 1px;
          //  background: #ffffff;
          // border: 1px solid #e5e5e5;
          z-index: 1;
        }
      }

      .text-danger {
        font-family: IBM Plex Sans, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        margin-top: 3px;
      }
      .payment_error {
        font-family: IBM Plex Sans, sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.66;
        margin-top: 5px;
        width: 100%;
        text-align: center;
        color: #d32f2f;
      }

      .error_border {
        border: 1px solid red !important;
      }
      .terms_section {
        border: 1px solid #e5e5e5;
        background: #ffffff;
        border-radius: 8px;
        border-width: 1px;
        padding: 10px 9px 0px;
        margin-left: 8px;
        .PrivateSwitchBase-input,
        .custom_checkbox {
          // width: 20px;
          // height: 20px;
          background: #ffffff;
          border-radius: 4px;
          border-width: 1px;
          border: 1px solid #e5e5e5;
        }
        .css-1wwi5p3 {
          background: transparent;
          width: 20px;
          height: 20px;
        }
        .terms_title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #4c524a !important;
          margin-bottom: 2px;
          width: max-content;
        }
        .terms_texts {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #4c524a;
        }
      }
    }
    .pay_button {
      width: 384px;
      border-radius: 4px;
      gap: 10px;
      background: #0155b7;
      padding: 12px;
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
    }
    .confirming_text {
      font-family: IBM Plex Sans;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      color: #4c524a;
      padding: 20px 3px;
      margin-bottom: 0;
    }
    .MuiInputLabel-root {
      font-family: IBM Plex Sans;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #4c524a !important;
      text-align: left;
    }
  }
  .price_frame {
    p {
      font-family: IBM Plex Sans;
      font-weight: 500;
      font-size: 16px;
    }
    .pay_title {
      line-height: font/line-height/5;
      color: #4c524a;
    }
    .pay_subtitle {
      font-size: 14px;
      color: #4c524a;
    }
    .price_title {
      font-weight: 600;
      font-size: 36px;
      line-height: 100%;
      color: #0d120b;
    }
    .price_alignment {
      position: relative;
      left: 35px;
      width: 90%;
      top: -19px;
    }
    .auto_activity {
      width: 40px;
      height: 40px !important;
      margin-right: 10px;
      position: relative;
      top: 32px;
    }
    .info-title {
      font-size: 14px;
      color: #0d120b;
      padding-left: 15px;
    }
    .info-description {
      font-size: 14px;
      color: #0d120b;
    }
    .divider_class {
      display: flex;
      justify-content: end;
      padding-top: 20px;
      hr {
        border: 1px solid #e6e6e6;
        width: 92%;
      }
    }
  }
}
.premier_modal {
  .MuiDialog-paper {
    border-radius: 10px;
  }
}

.footer_texts {
  display: flex;
  justify-content: center;
  p {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    width: 88%;
    text-align: center;
  }
}

.approval_title {
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: #0d120b;
}
.approval_texts {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4c524a;
  padding: 5px 9%;
  margin-bottom: 0;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 250px !important;
}
.ElementsApp input {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c524a;
}
